<template>
  <div class="vg_wrapper">
    <add-header
        @submit="submit('msinForm')"
        @cancel="cancel">
    </add-header>
    <el-form ref="msinForm" :rules="rules" :model="msinForm" label-width="120px" size="mini">
      <el-row>
        <el-col :md="8">
          <el-form-item label="入库编号" prop="msin_no">
            <el-input v-model="msinForm.msin_no" maxlength="10"  show-word-limit placeholder="请填写入库编号">
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="入库时间" prop="msin_time">
            <el-date-picker
              v-model="msinForm.msin_time"
              type="date"
              placeholder="选择入库时间">
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="vg_button">
        <el-button type="primary" plain size="small" @click="addRow(0)">导入物料待入库</el-button>
        <el-button type="primary" plain size="small" @click="addRow(1)">导入物料</el-button>
        <el-button type="danger" plain size="small" @click="delBank()">删除</el-button>
      </div>
      <el-row class="vd_mrt">
        <MsinAddEditIn @handleSelectionChange="handleSelect" :msinForm="msinForm"></MsinAddEditIn>
      </el-row>
      <el-dialog
          :title="title"
          :visible.sync="notice"
          width="70%">
        <MsinAddEditMpin @confirmIn="confirmIn" ref="MsinAddEditMpin"></MsinAddEditMpin>
      </el-dialog>
      <el-dialog
          :title="title"
          :visible.sync="notice1"
          width="70%">
        <MsinAddEditMtrl @confirmIn="confirmIn" ref="MsinAddEditMtrl"></MsinAddEditMtrl>
      </el-dialog>
      <el-row class="vg_mt_16 vd_bortop">
        <el-col>
          <inputUser :isCookie="true" ref="userData"></inputUser>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import {get,post} from "@api/request";
import addHeader from "@/views/component/addHeader";
import inputUser from "@/views/component/inputUser";
import MsinAddEditMpin from "@/views/StockManagement/MsinManage/TabChild/Component/MsinAddEditMpin";
import MsinAddEditMtrl from "@/views/StockManagement/MsinManage/TabChild/Component/MsinAddEditMtrl";
import MsinAddEditIn from "@/views/StockManagement/MsinManage/TabChild/Component/TabPanel/MsinAddEditIn";
import {msinAPI} from "@api/modules/msin";
export default {
  name: "MsinAddMain",
  components: {
    addHeader,
    inputUser,
    MsinAddEditIn,
    MsinAddEditMpin,
    MsinAddEditMtrl,
  },
  data(){
    return{
      msinForm:{
        msin_no:'',
        msin_time:'',
        msin_mtrl_list:[],
        key:0,
      },
      rules:{
        msin_no:[
          { required: true, trigger: 'blur' , message: ' '},
        ],
        msin_time:[
          { required: true, trigger: 'blur' , message: ' '},
        ],
      },
      removeList:[],
      msinList:[],
      notice:false,
      title:'',
      notice1:false,
    }
  },
  created() {
  },
  methods:{
    //提交表单
    submit(formName){
      this.$confirm('确认提交?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.saveInfo()
          } else {
            console.log('error submit!');
            return false;
          }
        });
      }).catch(()=>{

      })
    },
    //保存
    saveInfo() {
      const staffForm =  this.$refs.userData.getData()
      let msinForm = {};
      msinForm = Object.assign(msinForm,this.msinForm,staffForm);
      msinForm.msin_time = (new Date(msinForm.msin_time)).getTime() / 1000;
      post(msinAPI.addMsin,msinForm)
          .then(res => {
            if(res.data.code === 0){
              this.$message({
                type:'success',
                message:'保存成功'
              })
              const permId = this.$route.query.perm_id
              this.jump(`/msin_edit?perm_id=${permId}&form_id=${res.data.data.form_id}`)
              this.resetForm('msinForm')
            }else if(res.data.code === 7){
              this.$message({
                type:'error',
                message:'单据编号重复'
              })
              this.msinForm.msin_date = (new Date(this.msinForm.msin_date)).getTime() * 1000;
            } else {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({message: mg, type: tp});
              this.msinForm.msin_date = (new Date(this.msinForm.msin_date)).getTime() * 1000;
            }
          }).catch((res)=>{
            let msg = res.data.msg
            this.$message.error(msg)
          })
    },
    //取消
    cancel(){
      this.$confirm('确定取消新增?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      }).then(() => {
        this.resetForm('msinForm')
        this.$router.go(-1)
        this.$message({
          type: 'info',
          message: '已取消新增!'
        });
      }).catch(()=>{})
    },
    //删除选择的内容
    handleSelect(val){
      this.removeList = val;
    },
    // 删除物料信息按钮
    delBank(){
      for(let i=0;i<this.removeList.length;i++){
        if(this.msinForm.msin_mtrl_list.indexOf(this.removeList[i]) !== -1){
          let index = this.msinForm.msin_mtrl_list.indexOf(this.removeList[i]);
          this.msinForm.msin_mtrl_list.splice(index,1)
        }
      }
    },
    // 打开导入物料信息小窗口
    addRow(num){
      if(num === 0){
        this.notice = true;
      }else if(num === 1){
        this.notice1 = true;
      }
    },
    // 导入小窗口确定按钮
    confirmIn(val){
      this.notice = false;
      this.notice1 = false;
      if(val.length>0){
        this.msinList = val;
        let msinListCopy = [];
        for(let i=0;i<this.msinList.length;i++){
          let temp = {};
          if(this.msinList[i].mpin_id){
            temp.mpin_id = this.msinList[i].mpin_id;
            temp.stay_mpin_num = parseInt(this.msinList[i].mtrl_num)-parseInt(this.msinList[i].mpin_num);
          }
          temp.mtrl_id = this.msinList[i].mtrl_id;
          temp.mtrl_no = this.msinList[i].mtrl_no;
          temp.mtrl_name = this.msinList[i].mtrl_name;
          temp.mtrl_unit = this.msinList[i].mtrl_unit;
          msinListCopy.push(temp)
        }
        this.msinForm.msin_mtrl_list = this.msinForm.msin_mtrl_list.concat(msinListCopy);
        if(this.msinList[0].mpin_id){
          this.$refs.MsinAddEditMpin.clear()
        }else{
          this.$refs.MsinAddEditMtrl.clear()
        }
      }
    },
    //表单重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.msinForm.msin_mtrl_list = [];
    },
  }
}
</script>

<style scoped lang="scss">
.vd_mrt{
  margin-top: 20px;
}
.vd_bortop {
  border-top: #DCDFE6 solid 1px;
  padding-top: 20px;
}
</style>